<template>
  <div class="hello">
    <h1>{{ msg }}</h1>

    <ul class="arenaList box">
      <li v-for="(item, index) in items" :key="item.title" v-on:click="populateGame(index)">
        {{ item.title }} ({{ item.duration }} min)
      </li>
    </ul>
    <button v-on:click="getArenas">Uppdatera</button>

    <div id="gameStarter" v-bind:class="{ showGameStarter: showGameStarter }">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-5-tablet is-4-desktop is-3-widescreen">
              <!-- <form action="" class="box"> -->
                <div class="box">
                <div class="field"><label for="" class="label">{{ newGameArenaName }}</label>
                  <div class="control has-icons-left">
                    <input v-model="newGameTeam" type="text" placeholder="Lagnamn"
                      class="input" required="" autocomplete="off"
                      style="background-image: url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAASCAYAAABSO15qAAAAAXNSR0IArs4c6QAAAPhJREFUOBHlU70KgzAQPlMhEvoQTg6OPoOjT+JWOnRqkUKHgqWP4OQbOPokTk6OTkVULNSLVc62oJmbIdzd95NcuGjX2/3YVI/Ts+t0WLE2ut5xsQ0O+90F6UxFjAI8qNcEGONia08e6MNONYwCS7EQAizLmtGUDEzTBNd1fxsYhjEBnHPQNG3KKTYV34F8ec/zwHEciOMYyrIE3/ehKAqIoggo9inGXKmFXwbyBkmSQJqmUNe15IRhCG3byphitm1/eUzDM4qR0TTNjEixGdAnSi3keS5vSk2UDKqqgizLqB4YzvassiKhGtZ/jDMtLOnHz7TE+yf8BaDZXA509yeBAAAAAElFTkSuQmCC&quot;); background-repeat: no-repeat; background-attachment: scroll; background-size: 16px 18px; background-position: 98% 50%;"><span
                      class="icon is-small is-left"><i class="fa fa-envelope"></i></span></div>
                </div>
                <div class="field">                  
                  <div class="control has-icons-left"><input v-model="newGameNoPlayers" type="text" placeholder="Antal spelare"
                      class="input" required="" autocomplete="off"
                      style="background-image: url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAASCAYAAABSO15qAAAAAXNSR0IArs4c6QAAAPhJREFUOBHlU70KgzAQPlMhEvoQTg6OPoOjT+JWOnRqkUKHgqWP4OQbOPokTk6OTkVULNSLVc62oJmbIdzd95NcuGjX2/3YVI/Ts+t0WLE2ut5xsQ0O+90F6UxFjAI8qNcEGONia08e6MNONYwCS7EQAizLmtGUDEzTBNd1fxsYhjEBnHPQNG3KKTYV34F8ec/zwHEciOMYyrIE3/ehKAqIoggo9inGXKmFXwbyBkmSQJqmUNe15IRhCG3byphitm1/eUzDM4qR0TTNjEixGdAnSi3keS5vSk2UDKqqgizLqB4YzvassiKhGtZ/jDMtLOnHz7TE+yf8BaDZXA509yeBAAAAAElFTkSuQmCC&quot;); background-repeat: no-repeat; background-attachment: scroll; background-size: 16px 18px; background-position: 98% 50%;"><span
                      class="icon is-small is-left"><i class="fa fa-lock"></i></span></div>
                </div>

                <div class="field"><button  v-on:click="startGame" class="button is-success"> Starta </button></div>
                <button class="button is-danger" v-on:click="closeStartDialog">Stäng</button>

              <!-- </form> -->
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="dataCollector" v-bind:class="{ showDataCollector: showDataCollector }">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-5-tablet is-4-desktop is-3-widescreen">
              <div v-for="(dataPoint, dind) in dataTemplate" class="dataPointTitle" :key="dataPoint.title">
                <h2>{{ dataPoint.title }}</h2>
                <button v-for="(option, oind) in dataPoint.options" :key="option" class="optButton" v-bind:class="{ choosen: isOptionSelected(dind, oind) }" v-on:click="collectOption( dind , oind)">
                  {{ option }} 
                </button>
              </div>
              <button class="button is-primary" :disabled="!isDataComplete()" v-on:click="nextPlayer()">Nästa</button>
              <button class="button is-warning" :disabled="!areWeReadyToPlay()" v-on:click="allPlayersDone()">Nu spelar vi</button>
              <button class="button is-danger" v-on:click="closeStartDialog">Stäng</button>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div id="gameStarted" v-bind:class="{ showGameStarted: showGameStarted }">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-5-tablet is-4-desktop is-3-widescreen">
            <div class="box">
              <div class="startedTeam">{{ newGameTeam }} ({{ newGameNoPlayers}})</div>
              <div class="startedArena">{{ newGameArenaName }}</div>

              <img :src="joinQRcode">
              <p><a v-bind:href="gameLink">JOIN</a></p>
              <button class="button" v-on:click="closeStartDialog">Stäng</button>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 

 


</template>

<script>
  import axios from 'axios'

  export default {
    name: 'GameLeader',

    data() {
      return {
        items: [],
        spel: 0,
        newGameArenaId: '',
        newGameTeam: '',
        newGameNoPlayers: 0,
        newGameArenaName: '',
        createdGameId: '',
        count: 5,
        showGameStarter: false,
        showGameStarted: false,
        showDataCollector: false, 
        joinQRcode: '',
        gameLink: '',
        dataTemplate: {},
        collectedData: [],
        currentData: {}

      }
    },

    props: {
      msg: String
    },
    mounted() {
      this.getArenas();
      this.dataTemplate =  {
        "gender": {
          "title": "Kön",
          "options": {
            "female": "Hon",
            "nb": "Ickebinär",
            "male": "Han",
            "nd": "Privat"
          }
        },
        "age": {
          "title": "Ålder",
          "options": {
            "13": "12-13",
            "15": "14-15",
            "18": "16-18"
          }
        }
      }
    },
    methods: {
      closeStartDialog() {
        this.joinQRcode = '';
        this.showGameStarter = false;
        this.showGameStarted = false;
        this.showDataCollector = false;
        this.newGameArenaId = '';
        this.newGameTeam = '';
        this.newGameNoPlayers = 0;
      },
      collectOption(dataPoint, option) {
        this.currentData[dataPoint] = option      
      },
      nextPlayer() {
        console.log(Object.keys(this.currentData).length)
        this.collectedData.push(this.currentData)
        this.newGameNoPlayers = this.newGameNoPlayers + 1
        this.currentData = {}

      },
      allPlayersDone() {
        if (Object.keys(this.currentData).length !== 0) { this.nextPlayer() }
        this.showDataCollector = false
        this.showGameStarter = true
      },
      isDataComplete() {
        if (Object.keys(this.currentData).length === Object.keys(this.dataTemplate).length) {
          return true
        } else {
          return false
        }
      },
      areWeReadyToPlay() {
        if (this.isDataComplete() === true) { return true }
        if (Object.keys(this.collectedData).length >= 1) { return true }
      },
      isOptionSelected(dataPoint, option) {
       
        if (this.currentData[dataPoint] === option) {
          return true
        } else {
          return false
        }
      },
      startGame() {
        var self = this;

        var gameData = {
          team: "Laget lagom",
          players: "3",
        }
        gameData["team"] = self.newGameTeam
        gameData["players"] = self.newGameNoPlayers
        gameData["playerdata"] = self.collectedData



        let apiUrl = 'https://q.cqs.se/api/games/new/' + self.newGameArenaId

        const headers = {
          "Authorization": "Bearer my-token",
          "My-Custom-Header": "foobar"
        };

        axios.post(apiUrl, gameData, {
            headers
          })
          .then(function (response) {
            self.createdGameId = response.data.gameId
   
            self.showGameStarter = false;
            self.showGameStarted = true;
            self.joinQRcode = 'https://q.cqs.se/qrcodes/' + response.data.gameId + '-join.png';
            self.gameLink = 'https://xn--4ca.ventyret.se/inthewild?gameid=' + response.data.gameId;

          })
          .catch(function (error) {
            alert(error);
          })
      },


      populateGame(whatGame) {
        var self = this;

        this.showDataCollector = true;
        this.currentData = {};
        this.collectedData = [];
//        this.showGameStarter = true;
        
        var gameData = {}
        gameData["team"] = self.newGameTeam;
        gameData["players"] = self.newGameNoPlayers;
        gameData["playerData"] = {}
        this.newGameArenaId = this.items[whatGame]["arenaId"]
        this.newGameArenaName = this.items[whatGame]['title']

      },
      increment(nmbr) {
        // `this` will refer to the component instance
        this.count = this.count + nmbr
      },
      getArenas() {
        var self = this;
        let apiUrl = 'https://q.cqs.se/api/arenas'
        axios.get(apiUrl)
          .then(function (response) {

            self.count = 55
            self.items = response.data

          })
          .catch(function (error) {
            alert(error);
          });
      }
    }
  }
</script>

