<template>
  <div class="activeGames box">
    <h1>{{ msg }}</h1>

    <ul class="gamesList">
      <li class="gameItemTitle">
        <div class="bana">Bana</div><div class="lag">  Lag</div>
      </li>
      <li class="gameItem" v-for="(item) in activeGames" :key="item.title">
        <div class="bana">{{ item.title }}</div>
        <div class="lag">{{ item.team }} ({{ item.players}})</div>
        <div class="prog">Starttid: {{ item.start }}</div>
        <div class="status">
        Ledtrådar: {{ item.status.hints }} Hittade platser: {{ item.status.locationsVisited }} av {{ item.status.locations }}</div>
      </li>
    </ul>
    <button v-on:click="getGames">Uppdatera spel</button>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'ActiveGames',

    data() {
      return {
        items: [],
        activeGames: [],
        spel: 0,
        newGameArenaId: '',
        newGameTeam: '',
        newGameNoPlayers: 0,
        newGameArenaName: '',
        createdGameId: '',
        count: 5,
        showGameStarter: false,
        showGameStarted: false,
        showDataCollector: false, 
        joinQRcode: '',
        gameLink: '',
        dataTemplate: {},
        collectedData: [],
        currentData: {}

      }
    },

    props: {
      msg: String
    },
    mounted() {
      this.getGames()
      this.interval = setInterval(() => this.getGames(), 5000)
      
    },
    methods: {
      getGames() {
        var self = this;
        let apiUrl = 'https://q.cqs.se/api/games/status/active'
        axios.get(apiUrl)
          .then(function (response) {

            self.activeGames = response.data


          })
          .catch(function (error) {
            alert(error);
          });
      
      },
      allPlayersDone() {
        if (Object.keys(this.currentData).length !== 0) { this.nextPlayer() }
        this.showDataCollector = false
        this.showGameStarter = true
      },
      isDataComplete() {
        if (Object.keys(this.currentData).length === Object.keys(this.dataTemplate).length) {
          return true
        } else {
          return false
        }
      },
      areWeReadyToPlay() {
        if (this.isDataComplete() === true) { return true }
        if (Object.keys(this.collectedData).length >= 1) { return true }
      },
      isOptionSelected(dataPoint, option) {
       
        if (this.currentData[dataPoint] === option) {
          return true
        } else {
          return false
        }
      },

    }
  }
</script>

