<template>
  <div class="home">
    
    <GameLeader msg="Spelledare Malmö Stad"/>
    <ActiveGames msg="Aktiva spel"></ActiveGames>
  </div>
</template>

<script>
// @ is an alias to /src
import GameLeader from '@/components/GameLeader.vue'
import ActiveGames from '@/components/ActiveGames.vue'

export default {
  name: 'Home',
  components: {
    GameLeader,
    ActiveGames
  }
}
</script>
